.footer{
	bottom:0;
	right: 0;
	left:0;
	position: inherit;
	margin-bottom:-10px;
}
.credit{
	display: flex;
	height: 7vh;
	background-color: blueviolet;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    padding-bottom: 20px;
    font-weight: 700;
    color: #000000;
    padding: 10px;
    text-transform: capitalize;
}
.wrapper{
    position: relative;
    background-color: #6eb8c3f5;
    width: 100%;
    display: inline-block;
    z-index: 2;
    bottom: 0;
    right: 0;
    margin-top:0 auto;
}
.winter-wrapper{
	position: relative;
	width: 100%;
	height: 60vh;
	display: block;
	margin: 0 auto;
	z-index: 2;
	overflow: hidden;
}
.winter-wrap{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
    width: 100%;
	height: 100%;
	overflow: hidden;
}

.snow-wrap{
	position: absolute;
	top: 0;
	left: 0;
	width: 600px;
	height: 450px;
	display: block;
	margin: 0 auto;
	z-index: 1;
	overflow: hidden;
}
.snow-wrap.snd{
	top: 450px;
}
.snow-wrap.trd{
	top: 900px;
}
.snow-wrapper.big .snow-wrap span{
	width: 3px;
	height: 3px;
}
.snow-wrap span{
	position: absolute;
	top: 20px;
	left: 20px;
	width: 4px;
	height: 4px;
	border-radius: 50%;
	background-color: #fefefe;
}
.snow-wrap span:nth-child(2){
	top: 25px;
	left: 70px;
}
.snow-wrap span:nth-child(3){
	top: 30px;
	left: 120px;
}
.snow-wrap span:nth-child(4){
	top: 35px;
	left: 180px;
}
.snow-wrap span:nth-child(5){
	top: 20px;
	left: 240px;
}
.snow-wrap span:nth-child(6){
	top: 40px;
	left: 310px;
}
.snow-wrap span:nth-child(7){
	top: 30px;
	left: 365px;
}
.snow-wrap span:nth-child(8){
	top: 10px;
	left: 415px;
}
.snow-wrap span:nth-child(9){
	top: 20px;
	left: 470px;
}
.snow-wrap span:nth-child(10){
	top: 50px;
	left: 535px;
}
.snow-wrap span:nth-child(11){
	top: 35px;
	left: 585px;
}
.snow-wrap span:nth-child(12){
	top: 100px;
	left: 15px;
}
.snow-wrap span:nth-child(13){
	top: 90px;
	left: 65px;
}
.snow-wrap span:nth-child(14){
	top: 70px;
	left: 135px;
}
.snow-wrap span:nth-child(15){
	top: 80px;
	left: 165px;
}
.snow-wrap span:nth-child(16){
	top: 90px;
	left: 215px;
}
.snow-wrap span:nth-child(17){
	top: 60px;
	left: 265px;
}
.snow-wrap span:nth-child(18){
	top: 80px;
	left: 305px;
}
.snow-wrap span:nth-child(19){
	top: 110px;
	left: 325px;
}
.snow-wrap span:nth-child(20){
	top: 85px;
	left: 375px;
}
.snow-wrap span:nth-child(21){
	top: 95px;
	left: 415px;
}
.snow-wrap span:nth-child(22){
	top: 60px;
	left: 465px;
}
.snow-wrap span:nth-child(23){
	top: 75px;
	left: 515px;
}
.snow-wrap span:nth-child(24){
	top: 95px;
	left: 545px;
}
.snow-wrap span:nth-child(25){
	top: 65px;
	left: 575px;
}
.snow-wrap span:nth-child(26){
	top: 135px;
	left: 55px;
}
.snow-wrap span:nth-child(27){
	top: 145px;
	left: 115px;
}
.snow-wrap span:nth-child(28){
	top: 120px;
	left: 175px;
}
.snow-wrap span:nth-child(29){
	top: 105px;
	left: 215px;
}
.snow-wrap span:nth-child(30){
	top: 140px;
	left: 230px;
}
.snow-wrap span:nth-child(31){
	top: 125px;
	left: 275px;
}
.snow-wrap span:nth-child(32){
	top: 145px;
	left: 335px;
}
.snow-wrap span:nth-child(33){
	top: 105px;
	left: 405px;
}
.snow-wrap span:nth-child(34){
	top: 125px;
	left: 475px;
}
.snow-wrap span:nth-child(35){
	top: 140px;
	left: 525px;
}
.snow-wrap span:nth-child(36){
	top: 120px;
	left: 590px;
}
.snow-wrap span:nth-child(37){
	top: 180px;
	left: 15px;
}
.snow-wrap span:nth-child(38){
	top: 190px;
	left: 60px;
}
.snow-wrap span:nth-child(39){
	top: 210px;
	left: 115px;
}
.snow-wrap span:nth-child(40){
	top: 190px;
	left: 160px;
}
.snow-wrap span:nth-child(41){
	top: 175px;
	left: 210px;
}
.snow-wrap span:nth-child(42){
	top: 190px;
	left: 245px;
}
.snow-wrap span:nth-child(43){
	top: 175px;
	left: 300px;
}
.snow-wrap span:nth-child(44){
	top: 200px;
	left: 340px;
}
.snow-wrap span:nth-child(45){
	top: 195px;
	left: 410px;
}
.snow-wrap span:nth-child(46){
	top: 180px;
	left: 490px;
}
.snow-wrap span:nth-child(47){
	top: 210px;
	left: 530px;
}
.snow-wrap span:nth-child(48){
	top: 185px;
	left: 585px;
}
.snow-wrap span:nth-child(49){
	top: 235px;
	left: 35px;
}
.snow-wrap span:nth-child(50){
	top: 245px;
	left: 95px;
}
.snow-wrap span:nth-child(51){
	top: 220px;
	left: 135px;
}
.snow-wrap span:nth-child(52){
	top: 235px;
	left: 205px;
}
.snow-wrap span:nth-child(53){
	top: 250px;
	left: 250px;
}
.snow-wrap span:nth-child(54){
	top: 245px;
	left: 315px;
}
.snow-wrap span:nth-child(55){
	top: 225px;
	left: 385px;
}
.snow-wrap span:nth-child(56){
	top: 240px;
	left: 425px;
}
.snow-wrap span:nth-child(57){
	top: 255px;
	left: 485px;
}
.snow-wrap span:nth-child(58){
	top: 220px;
	left: 515px;
}
.snow-wrap span:nth-child(59){
	top: 245px;
	left: 545px;
}
.snow-wrap span:nth-child(60){
	top: 230px;
	left: 575px;
}
.snow-wrap span:nth-child(61){
	top: 280px;
	left: 35px;
}
.snow-wrap span:nth-child(62){
	top: 295px;
	left: 85px;
}
.snow-wrap span:nth-child(63){
	top: 300px;
	left: 145px;
}
.snow-wrap span:nth-child(64){
	top: 310px;
	left: 205px;
}
.snow-wrap span:nth-child(65){
	top: 270px;
	left: 275px;
}
.snow-wrap span:nth-child(66){
	top: 290px;
	left: 335px;
}
.snow-wrap span:nth-child(67){
	top: 265px;
	left: 395px;
}
.snow-wrap span:nth-child(68){
	top: 300px;
	left: 415px;
}
.snow-wrap span:nth-child(69){
	top: 285px;
	left: 465px;
}
.snow-wrap span:nth-child(70){
	top: 270px;
	left: 535px;
}
.snow-wrap span:nth-child(71){
	top: 290px;
	left: 565px;
}
.snow-wrap span:nth-child(72){
	top: 310px;
	left: 595px;
}
.snow-wrap span:nth-child(73){
	top: 350px;
	left: 25px;
}
.snow-wrap span:nth-child(74){
	top: 340px;
	left: 70px;
}
.snow-wrap span:nth-child(75){
	top: 350px;
	left: 135px;
}
.snow-wrap span:nth-child(76){
	top: 330px;
	left: 190px;
}
.snow-wrap span:nth-child(77){
	top: 320px;
	left: 245px;
}
.snow-wrap span:nth-child(78){
	top: 355px;
	left: 300px;
}
.snow-wrap span:nth-child(79){
	top: 345px;
	left: 355px;
}
.snow-wrap span:nth-child(80){
	top: 350px;
	left: 415px;
}
.snow-wrap span:nth-child(81){
	top: 320px;
	left: 450px;
}
.snow-wrap span:nth-child(82){
	top: 360px;
	left: 495px;
}
.snow-wrap span:nth-child(83){
	top: 355px;
	left: 545px;
}
.snow-wrap span:nth-child(84){
	top: 350px;
	left: 575px;
}
.snow-wrap span:nth-child(85){
	top: 390px;
	left: 5px;
}
.snow-wrap span:nth-child(86){
	top: 400px;
	left: 55px;
}
.snow-wrap span:nth-child(87){
	top: 380px;
	left: 115px;
}
.snow-wrap span:nth-child(88){
	top: 410px;
	left: 175px;
}
.snow-wrap span:nth-child(89){
	top: 385px;
	left: 235px;
}
.snow-wrap span:nth-child(90){
	top: 390px;
	left: 305px;
}
.snow-wrap span:nth-child(91){
	top: 370px;
	left: 375px;
}
.snow-wrap span:nth-child(92){
	top: 385px;
	left: 425px;
}
.snow-wrap span:nth-child(93){
	top: 405px;
	left: 485px;
}
.snow-wrap span:nth-child(94){
	top: 410px;
	left: 515px;
}
.snow-wrap span:nth-child(95){
	top: 395px;
	left: 545px;
}
.snow-wrap span:nth-child(96){
	top: 400px;
	left: 595px;
}
.snow-wrap span:nth-child(97){
	top: 420px;
	left: 25px;
}
.snow-wrap span:nth-child(98){
	top: 430px;
	left: 75px;
}
.snow-wrap span:nth-child(99){
	top: 420px;
	left: 125px;
}
.snow-wrap span:nth-child(100){
	top: 425px;
	left: 170px;
}
.snow-wrap span:nth-child(101){
	top: 440px;
	left: 235px;
}
.snow-wrap span:nth-child(102){
	top: 410px;
	left: 270px;
}
.snow-wrap span:nth-child(103){
	top: 425px;
	left: 335px;
}
.snow-wrap span:nth-child(104){
	top: 435px;
	left: 385px;
}
.snow-wrap span:nth-child(105){
	top: 410px;
	left: 440px;
}
.snow-wrap span:nth-child(106){
	top: 440px;
	left: 500px;
}
.snow-wrap span:nth-child(107){
	top: 430px;
	left: 535px;
}
.snow-wrap span:nth-child(108){
	top: 435px;
	left: 585px;
}

.sun{
	position: absolute;
	top: 40px;
	left: 70px;
	width: 37px;
	display: block;
	height: 37px;
    background-color: #ffc107;
    border-radius: 50%;
    box-shadow: 0 0 0 8px #ffc107, 0 0 0 20px #ffc107;
	z-index: 1;
	animation: brilla-sun 3s alternate infinite;
}
@keyframes brilla-sun{
	0%, 100%{
	box-shadow: 
        0 0 0 8px #e9fcf6,
        0 0 0 20px #dcf7ee;
	}
	50%{
	box-shadow:
        0 0 0 5px #e9fcf6,
        0 0 0 14px #dcf7ee;
	}
}
.bottom-snow{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	display: block;
	height: 31px;
	background-color: #fff;
	z-index: 1; 
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	overflow: hidden;
}
.bottom-snow span{
	position: absolute;
	right: 0;
	top: 0;
	width: 50%;
	height: 7px;
	display: block;
	background-color: #e6fcf9;
	border-radius: 4px;
}
.bottom-snow-over{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	display: block;
	height: 21px;
	z-index: 60; 
	border-top-left-radius: 14px;
	border-top-right-radius: 14px;
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	overflow: hidden;
}
.bottom-snow-over span{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 65%;
	display: block;
	height: 7px;
	background-color: #e6fcf9;
	z-index: 1; 
	border-radius: 4px;
}
.middle-snow{
	position: absolute;
	bottom: 21px;
	left: 15px;
	width: calc(100% - 30px);
	display: block;
	height: 30px;
	background-color: #d2f0e6;
	z-index: 1; 
	overflow: hidden;
}
.buildings {
	position: absolute;
	bottom: 51px;
	left: 15px;
	width: calc(100% - 30px);
	height: 399px;
	display: block;
	z-index: 2; 
	overflow: hidden;
}
.buildings span{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 40px;
	height: 180px;
	background-color: #9ac8bb;
}
.buildings span:nth-child(2){
	left: 40px;
	width: 55px;
	height: 240px;
}
.buildings span:nth-child(3){
	left: 95px;
	width: 65px;
	height: 140px;
}
.buildings span:nth-child(4){
	left: 160px;
	width: 50px;
	height: 120px;
}
.buildings span:nth-child(5){
	left: 210px;
	width: 10px;
	height: 80px;
}
.buildings span:nth-child(6){
	left: 220px;
	width: 60px;
	height: 140px;
}
.buildings span:nth-child(7){
	left: 280px;
	width: 50px;
	height: 270px;
}
.buildings span:nth-child(8){
	left: 330px;
	width: 30px;
	height: 170px;
}
.buildings span:nth-child(9){
	left: 360px;
	width: 20px;
	height: 120px;
}
.buildings span:nth-child(10){
	left: 380px;
	width: 60px;
	height: 230px;
}
.buildings span:nth-child(11){
	left: 440px;
	width: 40px;
	height: 170px;
}
.buildings span:nth-child(12){
	left: 480px;
	width: 50px;
	height: 190px;
}
.buildings span:nth-child(13){
	left: 530px;
	width: 40px;
	height: 150px;
}
.buildings.back {
	z-index: 1; 
}
.buildings.back span{
	width: 20px;
	height: 140px;
	background-color: #acd7cd;
}
.buildings.back span:nth-child(2){
	left: 20px;
	width: 60px;
	height: 200px;
}
.buildings.back span:nth-child(3){
	left: 80px;
	width: 40px;
	height: 220px;
}
.buildings.back span:nth-child(4){
	left: 120px;
	width: 20px;
	height: 170px;
}
.buildings.back span:nth-child(5){
	left: 140px;
	width: 60px;
	height: 250px;
}
.buildings.back span:nth-child(6){
	left: 200px;
	width: 60px;
	height: 290px;
}
.buildings.back span:nth-child(7){
	left: 260px;
	width: 40px;
	height: 210px;
}
.buildings.back span:nth-child(8){
	left: 300px;
	width: 65px;
	height: 310px;
}
.buildings.back span:nth-child(9){
	left: 365px;
	width: 45px;
	height: 200px;
}
.buildings.back span:nth-child(10){
	left: 410px;
	width: 60px;
	height: 260px;
}
.buildings.back span:nth-child(11){
	left: 470px;
	width: 50px;
	height: 240px;
}
.buildings.back span:nth-child(12){
	left: 520px;
	width: 50px;
	height: 220px;
}
.swing{
	position: absolute;
	bottom: 2px;
	left: 240px;
	width: 50px;
	height: 100px;
	border: 5px solid #366a78;
	border-color: #366a78 #366a78 transparent #366a78;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	display: block;
	z-index: 5; 
	overflow: hidden;
}
.swing-over-wrap{
	position: absolute;
	bottom: 2px;
	left: 265px;
	z-index: 6; 
	perspective: 400px;
}
.swing-over{
	position: absolute;
	bottom: 0;
	left: 0;
	margin-left: -25px;
	width: 50px;
	height: 95px;
	display: block;
	transform-origin: center top;
	animation: swinging 3s ease infinite;
}
@keyframes swinging{
	0%, 100%{
		transform: rotateX(25deg);
	}
	50%{
		transform: rotateX(-25deg);
	}
}
.swing-over span{
	position: absolute;
	bottom: 22px;
	left: 10px;
	width: 30px;
	height: 6px;
	border-radius: 3px;
	background-color: #f08139;
	display: block;
	z-index: 5; 
	overflow: hidden;
}
.swing-over span:nth-child(2){
	bottom: auto;
	top: 0;
	left: 13px;
	width: 2px;
	height: 74px;
	border-radius: 0;
	background-color: #4f4c4d;
	z-index: 4;
}
.swing-over span:nth-child(3){
	bottom: auto;
	top: 0;
	left: auto;
	right: 13px;
	width: 2px;
	height: 74px;
	border-radius: 0;
	background-color: #4f4c4d;
	z-index: 4;
}
.swing-left{
	position: absolute;
	bottom: 2px;
	left: 195px;
	width: 50px;
	height: 80px;
	border: 5px solid #366a78;
	border-color: #366a78 transparent transparent #366a78;
	border-top-left-radius: 10px;
	display: block;
	z-index: 4; 
}
.swing-left-back{
	position: absolute;
	bottom: 2px;
	left: 195px;
	width: 50px;
	height: 80px;
	display: block;
	z-index: 3; 
}
.swing-left-back span{
	position: absolute;
	top: 14px;
	left: -2px;
	width: 54px;
	height: 4px;
	border-radius: 2px;
	display: block;
	background-color: #986233;
}
.swing-left-back span:nth-child(2){
	top: 30px;
}
.swing-left-back span:nth-child(3){
	top: 46px;
}
.swing-left-back span:nth-child(4){
	top: 62px;
}
.box{
	position: absolute;
	bottom: 7px;
	left: 100px;
	display: block;
	z-index: 30;
}
.box span{
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100px;
	height: 12px;
	background-color: #986233;
	display: block;
	z-index: 5;
}
.box span:nth-child(2){
	bottom: 12px;
	height: 4px;
	background-color: #5e3e25;
}
.box span:nth-child(3){
	bottom: 16px;
	height: 8px;
	left: -6px;
	width: 112px;
	border-radius: 2px;
	background-color: #f08139;
}
.box span:nth-child(4){
	bottom: -16px;
	left: 0;
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background-color: #fff;
	z-index: 4;
}
.box span:nth-child(5){
	bottom: 12px;
	left: 10px;
	height: 20px;
	width: 90px;
	border-top-right-radius: 150%;
	border-top-left-radius: 150%;
	background-color: #fff;
	z-index: 4;
}
.box span:nth-child(6){
	bottom: 7px;
	left: 25px;
	height: 30px;
	width: 60px;
	border-top-right-radius: 150%;
	border-top-left-radius: 150%;
	background-color: #b6ded5;
	z-index: 3;
}
.bucket{
	position: absolute;
	bottom: 30px;
	left: 42px;
	display: block;
	z-index: 5;
	perspective: 100px;
}
.bucket span{
	position: absolute;
	bottom: 0;
	left: 50%;
	margin-left: -8px;
	height: 25px;
	width: 16px;
	display: block;
	background-color: #b94b00;
	z-index: 1;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	transform-origin: center bottom;
	transform: rotateX(-55deg);
}
.bucket span:nth-child(2){
	bottom: 15px;
	left: 50%;
	margin-left: -12px;
	height: 5px;
	width: 24px;
	background-color: #ef8136;
	z-index: 2;
	border-radius: 2px;
	transform-origin: center bottom;
	transform: rotateX(0);
}
.shovel{
	position: absolute;
	bottom: 22px;
	left: 13px;
	display: block;
	z-index: 2;
	transform-origin: center bottom;
	transform: rotateZ(-20deg);
}
.shovel span{
	position: absolute;
	bottom: 0;
	left: 0;
	display: block;
	z-index: 1;
	height: 18px;
	width: 14px;
	border-bottom-left-radius: 70%;
	border-bottom-right-radius: 70%;
	background-color: #366a78;
}
.shovel span:nth-child(2){
	bottom: 13px;
	left: 5px;
	display: block;
	z-index: 2;
	height: 33px;
	width: 4px;
	border-radius: 2px;
	background-color: #4c9cb5;
}
.shovel span:nth-child(3){
	bottom: 42px;
	left: 2px;
	z-index: 2;
	height: 4px;
	width: 10px;
	border-radius: 2px;
	background-color: #4c9cb5;
}
.lamp{
	position: absolute;
	bottom: 21px;
	left: 340px;
	display: block;
	z-index: 2;
	transform-origin: center bottom;
	perspective: 40px;
}
.lamp-wrap{
	position: absolute;
	bottom: 0;
	left: -9px;
	display: block;
	z-index: 2;
}
.lamp-wrap span{
	position: absolute;
	bottom: 0;
	left: 0;
	height: 6px;
	width: 18px;
	display: block;
	z-index: 2;
	background-color: #666;
}
.lamp-wrap span:nth-child(2){
	bottom: 6px;
	left: 2px;
	height: 55px;
	width: 14px;
	background-color: #333;
	transform-origin: center bottom;
	transform: rotateX(20deg);
}
.lamp-wrap span:nth-child(3){
	bottom: 38px;
	left: 4px;
	height: 8px;
	width: 10px;
	background-color: #666;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}
.lamp-wrap span:nth-child(4){
	bottom: 46px;
	left: 6px;
	height: 40px;
	width: 6px;
	background-color: #333;
}
.lamp-wrap span:nth-child(5){
	bottom: 86px;
	left: 3px;
	height: 6px;
	width: 12px;
	border-radius: 4px;
	background-color: #666;
}
.lamp-wrap span:nth-child(6){
	bottom: 92px;
	left: 7px;
	height: 18px;
	width: 4px;
	background-color: #333;
}
.lamp-wrap span:nth-child(7){
	bottom: 110px;
	left: 4px;
	height: 4px;
	width: 10px;
	background-color: #333;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}
.lamp-wrap span:nth-child(8){
	bottom: 114px;
	left: 4px;
	height: 6px;
	width: 10px;
	box-shadow: 
		inset 4px 0 0 0px #fffdf1,
		12px 0 14px -0.6px #fceea5, 
		-12px 0 10px -0.6px #fceea5;	
	background-color: #fceea5;
	transform-origin: center bottom;
	transform: rotateX(-50deg);
	z-index: 1;
	animation: lamp 2s alternate infinite;
}
@keyframes lamp{
	0%, 100%{
	box-shadow: 
		inset 4px 0 0 0px #fffdf1,
		12px 0 14px -0.6px #fceea5, 
		-12px 0 10px -0.6px #fceea5;	
	}
	50%{
	box-shadow: 
		inset 4px 0 0 0px #fffdf1,
		9px 0 11px -1px #fceea5, 
		-9px 0 7px -1px #fceea5;	
	}
}
.lamp-wrap span:nth-child(9){
	bottom: 133px;
	left: 1px;
	height: 5px;
	width: 16px;
	border-radius: 2px;
	background-color: #333;
	z-index: 3;
}
.lamp-wrap span:nth-child(10){
	bottom: 132px;
	left: 3px;
	height: 12px;
	width: 12px;
	border-top-left-radius: 100%;
	border-top-right-radius: 100%;
	background-color: #333;
	z-index: 3;
}
.lamp-wrap span:nth-child(11){
	bottom: 140px;
	left: 7px;
	height: 8px;
	width: 4px;
	border-radius: 2px;
	background-color: #333;
	z-index: 3;
}
.lamp-wrap span:nth-child(12){
	left: 3px;
	bottom: 137px;
	border-radius: 4px;
	width: 10px;
	height: 5px;
	background-color: #fff;
	z-index: 12;
	transform: skew(32deg, -4deg);
}
.bench{
	position: absolute;
	bottom: 21px;
	left: 50%;
	display: block;
	z-index: 2;
	transform-origin: center bottom;
	perspective: 40px;
}
.bench-wrap{
	position: absolute;
	bottom: 0;
	left: 0;
	display: block;
	z-index: 2;
}
.bench-wrap span{
	position: absolute;
	bottom: 0;
	left: 5px;
	display: block;
	z-index: 5;
	width: 7px;
	height: 40px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	background-color: #be7435;
}
.bench-wrap span:nth-child(2){
	left: 85px;
}
.bench-wrap span:nth-child(3){
	left: 0;
	bottom: 33px;
	border-radius: 4px;
	width: 12px;
	height: 7px;
}
.bench-wrap span:nth-child(4){
	left: 85px;
	bottom: 33px;
	border-radius: 4px;
	width: 12px;
	height: 7px;
}
.bench-wrap span:nth-child(5){
	left: 5px;
	bottom: 10px;
	border-radius: 0;
	width: 85px;
	height: 6px;
	background-color: #8c592c;
	z-index: 4;
}
.bench-wrap span:nth-child(6){
	left: 5px;
	bottom: 22px;
	border-radius: 0;
	width: 85px;
	height: 6px;
	background-color: #5e3e25;
	z-index: 4;
}
.bench-wrap span:nth-child(7){
	bottom: 35px;
	width: 7px;
	height: 20px;
	background-color: #8c592c;
	z-index: 4;
}
.bench-wrap span:nth-child(8){
	left: 85px;
	bottom: 35px;
	width: 7px;
	height: 20px;
	background-color: #8c592c;
	z-index: 4;
}
.bench-wrap span:nth-child(9){
	left: 5px;
	bottom: 47px;
	border-radius: 0;
	width: 85px;
	height: 6px;
	background-color: #5e3e25;
	z-index: 3;
}
.bench-wrap span:nth-child(10){
	left: 50px;
	bottom: 25px;
	width: 9px;
	height: 26px;
	background-color: #be7435;
	border-radius: 0;
	z-index: 1;
}
.bench-wrap span:nth-child(11){
	left: 62px;
	bottom: 25px;
	width: 9px;
	height: 26px;
	background-color: #be7435;
	border-radius: 0;
	z-index: 1;
}
.bench-wrap span:nth-child(12){
	left: 74px;
	bottom: 25px;
	width: 9px;
	height: 26px;
	background-color: #be7435;
	border-radius: 0;
	z-index: 1;
}
.bench-wrap span:nth-child(13){
	left: 38px;
	bottom: 25px;
	width: 9px;
	height: 26px;
	background-color: #be7435;
	border-radius: 0;
	z-index: 1;
}
.bench-wrap span:nth-child(14){
	left: 26px;
	bottom: 25px;
	width: 9px;
	height: 26px;
	background-color: #be7435;
	border-radius: 0;
	z-index: 1;
}
.bench-wrap span:nth-child(15){
	left: 14px;
	bottom: 25px;
	width: 9px;
	height: 26px;
	background-color: #be7435;
	border-radius: 0;
	z-index: 1;
}
.bench-wrap span:nth-child(16){
	left: 11px;
	bottom: 52px;
	border-radius: 30%;
	width: 65px;
	height: 4px;
	background-color: #fff;
	z-index: 12;
	transform: skew(52deg, 1deg);
}
.bin{
	position: absolute;
	bottom: 21px;
	right: 30px;
	width: 25px;
	height: 40px;
	display: block;
	border-top-left-radius: 6px;
	border-top-right-radius: 6px;
	background-color: #ce6f13;
	z-index: 2;
}
.bin span{
	position: absolute;
	top: 4px;
	right: 3px;
	width: 19px;
	height: 10px;
	display: block;
	border-radius: 4px;
	background-color: #333;
	z-index: 2;
}
.bin span:nth-child(2){
	top: 7px;
	background-color: #ff8f45;
	z-index: 1;
}

.tree{
	position: absolute;
	bottom: 7px;
	left: 40px;
	display: block;
	z-index: 50;
	perspective: 100px;
}
.tree.right{
	bottom: 21px;
	left: auto;
	right: 120px;
	transform: scale(0.9);
}
.tree span{
	position: absolute;
	bottom: 72px;
	left: -4px;
	display: block;
	z-index: 3;
	width: 100px;
	height: 100px;
	border-radius: 50%;
	background-color: #f2fefc;
	box-shadow: inset -10px 0 14px 0 #c5f2ed;
	transform: rotate(20deg);
}
.tree span:nth-child(2){
	bottom: 72px;
	left: -24px;
	z-index: 2;
	width: 70px;
	height: 70px;
	box-shadow: inset 5px 0 7px 0 #c5f2ed;
	transform: rotate(-20deg);
}
.tree span:nth-child(3){
	bottom: 97px;
	left: -9px;
	z-index: 2;
	width: 90px;
	height: 90px;
	box-shadow: inset 5px 0 7px 0 #c5f2ed;
	transform: rotate(40deg);
}
.tree span:nth-child(4){
	bottom: 0;
	left: 31px;
	z-index: 1;
	width: 18px;
	height: 100px;
	border-radius: 0;
	background-color: #625044;
	box-shadow: inset 6px 0 13px 0 #b58d74;
	transform: rotate(0);
	transform-origin: center bottom;
	transform: rotateX(10deg);
}
.tree span:nth-child(5){
	bottom: 40px;
	left: 36px;
	z-index: 0;
	width: 6px;
	height: 40px;
	border-radius: 0;
	background-color: #625044;
	box-shadow: none;
	transform: rotate(0);
	transform-origin: center bottom;
	transform: rotate(20deg);
}
.tree span:nth-child(6){
	bottom: 50px;
	left: 29px;
	z-index: 1;
	width: 6px;
	height: 40px;
	border-radius: 0;
	background-color: #625044;
	box-shadow: none;
	box-shadow: inset -3px -4px 22px -4px #b18b73,
	           0 5px 7px -7px #b18b73;
	transform: rotate(0);
	transform-origin: center bottom;
	transform: rotate(-25deg);
}

.goup{
		position: relative;
		left:45%;
		top:30%;
		text-shadow: 0px 10px 10px grey;
		font-size: 40px;
		cursor: pointer;
		width:100px;
		transition:.3s;
	

	}
.goup:active{
	text-shadow: 0px 0px 0px grey;
	transform:scale(1.3);
	}
.goup:hover{
	
	text-shadow: 0px 0px 0px grey;
}