.pre{
  background: #ededed;
  font-family: "Raleway", sans-serif;
}
.outline {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.loader > div {
  display: inline-flex;
  width: 25px;
  height: 25px;
  border-radius: 100%;
  background: #ffc107;
  -webkit-animation: bounce 1.48s ease-in-out infinite both;
          animation: bounce 1.48s ease-in-out infinite both;
}
.loader > div:nth-child(1) {
  -webkit-animation-delay: -0.32s;
          animation-delay: -0.32s;
}
.loader > div:nth-child(2) {
  -webkit-animation-delay: -0.16s;
          animation-delay: -0.16s;
}

@-webkit-keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes bounce {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes beat {
  to {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}
@keyframes beat {
  to {
    -webkit-transform: scale(1.5);
            transform: scale(1.5);
  }
}
