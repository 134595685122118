.myname{
font-size: 45px;
font-weight: 700;
line-height: 54px;
color: rgb(34, 34, 34);
text-shadow: rgb(128, 98, 73) 9px 3px 14px 0px;
}
.biolinep{
    font-size: 24px;
    font-weight: 500;
    line-height: 30.5555px;
    color: rgb(51, 51, 51);
}
