.Hero{
    height: 90vh;
    display: flex;
    position: relative;
    background-image: linear-gradient(to top left, #FFEB3B,#FF5722);
    background-color:#F4D03F;
    z-index: 1;
    
}
a{
    color: #dfe6ec;
    text-decoration: none;
    background-color: transparent;
}
.Hero-logo{
    height: 50px;
    position: absolute;
    top: 30px;
    left: 24px;
    font-size: 50px;
    cursor:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='34' height='41' viewport='0 0 100 100' style='fill:black;font-size:20px;'><text y='50%'>🔥</text></svg>") 16 0,auto; /*emojicursor.app*/
}
.Hero-menu{
    position: absolute;
    top: 30px;
    right: 24px;
    text-shadow: 0px 5px 11px #40303b;
    cursor: pointer;
    transition: .4s;
    z-index: 20;

}
.block {
    height: 60px;
    width: 60px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px; 
    transition: all 1s ease;
   background-image: linear-gradient(90deg, #fd5581, #fd8b55);
   box-shadow: 0 2px 18px 0 rgba(248, 94, 122, 0.39), 0 1px 2px 0 rgba(248, 91, 124, 0.33);   
  }
  
  .cta {
    height: 50px;
    width: 50px;
    cursor: pointer; }
  
  .toggle-btn {
    height: 2px;
    width: 25px;
    background-color: #fff;
    position: relative;
    top: 50%;
    left: 0;
    margin: auto;
    transition: all 0.3s ease-in-out; }
    .toggle-btn:before {
      content: "";
      height: 2px;
      width: 25px;
      box-shadow: 0 -10px 0 0 #fff;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.3s ease-in-out; }
    .toggle-btn:after {
      content: "";
      height: 2px;
      width: 25px;
      box-shadow: 0 10px 0 0 #fff;
      position: absolute;
      top: 0;
      left: 0;
      transition: all 0.3s ease-in-out; }
  .active .type16 {
    transform: rotate(360deg);
    transition: all 0.6s ease-in-out; }
  .active .type16:before {
      left: 6px;
      transform: translateY(6px) rotate(-45deg); }
    .active .type16:after {
      left: 6px;
      transform: translateY(-8px) rotate(45deg); }
  .active .toggle-btn {
    background-color: transparent;
    transition: all 0.2s ease-in-out; }
    .active .toggle-btn:before {
      top: 0;
      transition: all 0.3s ease-in-out; }
    .active .toggle-btn:after {
      top: 0;
      transition: all 0.3s ease-in-out; }

.header{
    position: absolute;
    top:30%;
    left:0;
    color:white;
}
.header h1{
    font-weight: 700;
    font-size: 8vw;
    text-shadow:0px 5px 11px #40303b;
    
    
}
.header h2{
    font-weight: 700;
    font-size: 4vw;
    text-shadow: 5px 10px 15px black;
    
    
}
.social-icon{
    list-style: none;
    
}
.icons_{
    display: inline-block;
    padding-right:20px;
    font-size: 3em;
    
    
}
.icons_ i{
    text-shadow: 6px 5px 10px black;
    transition: .5s;
    cursor: pointer;
}

.icons_ i:hover{
    color:#e83e8c;
    transform: translateY(1px);
    text-shadow: 0px 1px 2px grey;
    
}
.base{
    height: 10vh !important;
    position: relative;
    bottom: 0;
    left:0;
    background-color: #f35f19;
}
.menu{
    z-index: 2;
    display: flex;
    height: 100vh;
    background-color:#000000;
    top:0;
    bottom: 0;
    right: 0;
    left:0;
}


.overlay{
    
    animation: overlayani ;
    transition: .5s;
    background-color: #51dc35;
    height: 100vh;
    width: 0%;
    overflow-y: hidden;
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    cursor:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='34' height='41' viewport='0 0 100 100' style='fill:black;font-size:20px;'><text y='50%'>🔥</text></svg>") 16 0,auto; /*emojicursor.app*/
    
}
@keyframes overlayani{
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
.overlay-content {
    position: relative;
    top: 25%;
    width: 100%;
    text-align: center;
    margin-top: 30px;
    
  }
  
  .overlay a {
    text-transform: capitalize;
    font-weight: 700;
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #0e0d0d;
    display: block;
    transition: 0.3s;
    cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='34' height='41' viewport='0 0 100 100' style='fill:black;font-size:20px;'><text y='50%'>👆</text></svg>") 16 0,auto; /*emojicursor.app*/;
  }
  
  .overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
  }
  

  
  @media screen and (max-width: 450px) {
    .overlay {overflow-y: auto;}
    .overlay a {font-size: 20px}
    .icons_{
        font-size: 2em;
        padding-right: 10px;
    }
    .header h1{
        font-weight: 700;
        font-size: 9vw;
        text-shadow:0px 5px 11px #40303b;
        line-height: 2;
        
        
    }
    .header h2{
        font-weight: 700;
        font-size: 6vw;
        text-shadow: 5px 10px 15px black;
        
        
    }
    .Hero-logo{
        height: 50px;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 50px;
        cursor:url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'  width='34' height='41' viewport='0 0 100 100' style='fill:black;font-size:20px;'><text y='50%'>🔥</text></svg>") 16 0,auto; /*emojicursor.app*/
    }
    .Hero-menu{
        position: absolute;
        top: 0;
        right: 0;
        text-shadow: 0px 5px 11px #40303b;
        cursor: pointer;
        transition: .4s;
        z-index: 20;
    
    }
  }