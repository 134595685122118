
.titleofblog{
    color:#212529;
    font-weight: 700;
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 46px;
    box-sizing: border-box;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;

    
}
.titleofblog:hover{
    color:#007bff;
}
.navbg{
    background: #D2B4DE;
    box-shadow: 0px 4px 17px 0px #512E5F;
    border-bottom: 1px solid #512e5f6e;
}
.poster{
    font-size: 22px;
    font-style: italic;
    margin-top: 0;
    color: #868e96;
    font-size: 20px;
    font-weight: 500;
}

a{
    text-decoration: none;

}
a:link{
    text-decoration: none;
}
a:visited{
    text-decoration: none;
}
/* stylelint-disable selector-list-comma-newline-after */
.blog-header {
    line-height: 1;
    border-bottom: 1px solid #e5e5e5;
}
.blog-header-logo {
    font-family: "Playfair Display", Georgia, "Times New Roman", serif;
    font-size: 2.25rem;
}
.blog-header-logo:hover {
    text-decoration: none;
}

.display-4 {
    font-size: 2.5rem;
}
@media (min-width: 768px) {
    .display-4 {
        font-size: 3rem;
    }
}
.nav-scroller {
    position: relative;
    z-index: 2;
    height: 2.75rem;
    overflow-y: hidden;
}
.nav-scroller .nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding-bottom: 1rem;
    margin-top: -1px;
    overflow-x: auto;
    text-align: center;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
}
.nav-scroller .nav-link {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: .875rem;
}
.card-img-right {
    height: 100%;
    border-radius: 0 3px 3px 0;
}
.flex-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
}
.h-250 {
    height: 250px;
}
@media (min-width: 768px) {
    .h-md-250 {
        height: 250px;
    }
}
/*
 * Blog name and description
 */
.blog-title {
    margin-bottom: 0;
    font-size: 2rem;
    font-weight: 400;
}
.blog-description {
    font-size: 1.1rem;
    color: #999;
}
@media (min-width: 40em) {
    .blog-title {
        font-size: 3.5rem;
    }
}
/* Pagination */
.blog-pagination {
    margin-bottom: 4rem;
}
.blog-pagination > .btn {
    border-radius: 2rem;
}
/*
 * Blog posts
 */
.blog-post {
    margin-bottom: 4rem;
}
.blog-post-title {
    margin-bottom: .25rem;
    font-size: 2.5rem;
}
.blog-post-meta {
    margin-bottom: 1.25rem;
    color: #999;
}
/*
 * Footer
 */
.blog-footer {
    padding: 2.5rem 0;
    color: #999;
    text-align: center;
    background-color: #f9f9f9;
    border-top: .05rem solid #e5e5e5;
}
.blog-footer p:last-child {
    margin-bottom: 0;
}
.brand-name{
    font-family: inherit;
    font-weight: 700;
    font-size: 24px;
    transition: .2s;
    cursor: pointer;
    transition-timing-function: cubic-bezier(0.99, 0.46, 0.18, 1.29);
    display: inline-block;
    color:rgb(25,25,25);

}
.brand-name:hover{
    transform: scale(1.2);
}